import "./infrastructure-resume.scss";

import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const InfrastructureResume: React.FC = () => {
  return (
    <section className={"o-infrastructure-resume"}>
      <div className={"c-infrastructure-resume"}>
        <div className="m-infrastructure-resume__info">
          <h2>Instalaciones Grupo Usieto</h2>
          <p>
            Disponemos de 80.000 m³ de cámaras a diferentes temperaturas tanto refrigerado como congelado y temperaturas
            más específicas para otros productos para entregar la mercancía en condiciones óptimas.
          </p>
        </div>
        <div className="m-infrastructure-resume__seals">
          <StaticImage
            src={"../../Summary/ceroCo.png"}
            alt={"CeroCO"}
          />
          <StaticImage
            src={"../../Summary/rsa.png"}
            alt={"RSA"}
          />
        </div>
      </div>
    </section>
  );
};

export default InfrastructureResume;
